
import { FlowStep } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as sort from '@/helpers/sort'
import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'

@Component({
  name: 'FlowStepControlRestart'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentFlowStepId () {
    return this.$queryValue('flow_step')
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.diagramId === this.currentDiagram?.id && o.handleId === this.currentFlowHandleId)
  }

  get currentFlowStep () {
    return this.currentFlowStepId ? this.currentFlow?.steps[this.currentFlowStepId] : undefined
  }

  get currentFlowStepsPathSteps () {
    return Object
      .values(this.currentFlow?.steps || {})
      .filter(o => !o.type?.endsWith('-path') && (!o.pathId || this.currentFlowPathIds.includes(o.pathId)))
      .reduce<FlowStep[][]>((p, c) => {
        const existingGroup = p.find(o => c.pathId && o[0].pathId && c.pathId === o[0].pathId)
        if (existingGroup) {
          existingGroup.push(c)
          return p
        } else {
          return [...p, [c]]
        }
      }, [])
      .sort((a, b) => sort.index(a[0], b[0]))
      .map(o => o.sort(sort.pathIndex))
      .flat()
  }

  get restartLink () {
    const firstFlowStep = this.currentFlowStepsPathSteps[0]
    if (firstFlowStep && this.currentFlowStepId !== firstFlowStep?.id) {
      return {
        flow_step: firstFlowStep.id
      }
    }
  }
}
