
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

@Component({
  name: 'FlowStepDescription'
})
export default class extends Vue {
  @Ref() readonly descriptionField!: any

  @Prop() readonly description?: string

  model = ''
  editing = false

  mounted () {
    if (this.description) {
      this.model = this.description || ''
    }
  }

  @Watch('description')
  onDescriptionChanged (description: string, prevDescription: string) {
    if (description !== prevDescription) {
      this.model = description || ''
    }
  }

  focusField () {
    this.editing = true
  }

  blurField () {
    this.editing = false

    if (this.description !== this.model) {
      this.$emit('change', this.model)
    }
  }

  enterKey (event: KeyboardEvent) {
    if (!event.shiftKey) {
      event.preventDefault()
      this.descriptionField.blur()
    }
  }
}
