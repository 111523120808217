
import { DiagramConnection, ModelConnection } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'

@Component({
  name: 'FlowPickerConnection'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)

  @Ref() readonly menuRef!: any

  @Prop() readonly id?: string
  @Prop() readonly connections!: (DiagramConnection & { model: ModelConnection })[]
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop() readonly disabledMessage!: string

  search = ''
  searchFocused = false

  get filteredConnectionsFuzzy () {
    return new Fuse(this.connections, {
      keys: [
        'model.name'
      ],
      threshold: 0.3
    })
  }

  get filteredConnections () {
    return this.search ? this.filteredConnectionsFuzzy.search(this.search).map(o => o.item) : this.connections
  }

  get connection () {
    return this.connections.find(o => o.id === this.id)
  }

  get text () {
    if (this.id && !this.connection) {
      return 'Removed'
    } else if (this.id && this.connection) {
      return this.connection.model.name || 'Connection'
    } else if (this.disabled) {
      return 'Unavailable'
    } else {
      return 'Select connection'
    }
  }

  get textClass () {
    if (this.id && !this.connection) {
      return 'error--text'
    } else if (this.id && this.connection && !this.disabled) {
      return 'white--text'
    } else {
      return 'grey--text text--lighten-3'
    }
  }

  addStepHoverId (stepHoverId: string) {
    this.editorModule.addStepHoverId(stepHoverId)
  }

  removeStepHoverId (stepHoverId: string) {
    this.editorModule.removeStepHoverId(stepHoverId)
  }

  resetSearch () {
    this.search = ''
  }

  close () {
    this.menuRef.isActive = false
  }
}
