
import { Flow } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

@Component({
  name: 'FlowPickerFlow'
})
export default class extends Vue {
  @Ref() readonly menuRef!: any

  @Prop() readonly id?: string
  @Prop() readonly flows!: Flow[]

  search = ''
  searchFocused = false

  get filteredFlowsFuzzy () {
    return new Fuse(this.flows, {
      keys: ['name'],
      threshold: 0.3
    })
  }

  get filteredFlows () {
    return this.search ? this.filteredFlowsFuzzy.search(this.search).map(o => o.item) : this.flows
  }

  get flow () {
    return this.flows.find(o => o.id === this.id)
  }

  get text () {
    if (this.id && this.flow) {
      return this.flow.name
    } else if (this.id) {
      return 'Removed'
    } else {
      return 'Select flow'
    }
  }

  get textClass () {
    if (this.id && !this.flow) {
      return 'error--text'
    } else {
      return 'white--text'
    }
  }

  resetSearch () {
    this.search = ''
  }

  close () {
    this.menuRef.isActive = false
  }
}
