
import { FlowStepType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'FlowPickerConnection'
})
export default class extends Vue {
  @Prop() readonly type?: FlowStepType
  @Prop() readonly types!: FlowStepType[]
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop() readonly disabledMessage?: string

  get items () {
    const items: { id: FlowStepType, icon: string, text: string, disabledText?: string }[] = [
      {
        disabledText: 'There are no outgoing connections from your selection',
        icon: '$fas-long-arrow-alt-right',
        id: 'outgoing',
        text: 'Outgoing'
      },
      {
        disabledText: 'There are no incoming connections from your selection',
        icon: '$custom-flow-reply',
        id: 'reply',
        text: 'Reply'
      },
      {
        icon: '$fas-sync',
        id: 'self-action',
        text: 'Self action'
      }
    ]
    return items
  }

  get item () {
    return this.items.find(o => o.id === this.type)
  }
}
