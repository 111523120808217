
import { DiagramObject, ModelObject } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { EditorModule } from '@/modules/editor/store'

@Component({
  name: 'FlowPickerObject'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)

  @Ref() readonly menuRef!: any

  @Prop() readonly id?: string
  @Prop() readonly objects!: (DiagramObject & { model: ModelObject })[]
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop() readonly disabledMessage!: string

  search = ''
  searchFocused = false

  iconUrlForTheme = iconUrlForTheme

  get filteredObjectsFuzzy () {
    return new Fuse(this.objects, {
      keys: [
        'model.name',
        'model.type'
      ],
      threshold: 0.3
    })
  }

  get filteredObjects () {
    return this.search ? this.filteredObjectsFuzzy.search(this.search).map(o => o.item) : this.objects
  }

  get object () {
    return this.objects.find(o => o.id === this.id)
  }

  get text () {
    if (this.id && this.object) {
      return this.object.model.name || `${this.object.model.type.slice(0, 1).toUpperCase()}${this.object.model.type.slice(1)}`
    } else if (this.id) {
      return 'Removed'
    } else if (this.disabled) {
      return 'Unavailable'
    } else {
      return 'Select object'
    }
  }

  get textClass () {
    if (this.id && !this.object) {
      return 'error--text'
    } else if (this.id && this.object && !this.disabled) {
      return 'white--text'
    } else {
      return 'grey--text text--lighten-3'
    }
  }

  addStepHoverId (stepHoverId: string) {
    this.editorModule.addStepHoverId(stepHoverId)
  }

  removeStepHoverId (stepHoverId: string) {
    this.editorModule.removeStepHoverId(stepHoverId)
  }

  resetSearch () {
    this.search = ''
  }

  close () {
    this.menuRef.isActive = false
  }
}
