
import { FlowStep } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  components: {
    OrganizationUpgradeMenu
  },
  name: 'FlowStepAddMenu'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly step?: FlowStep
  @Prop({ default: true, type: Boolean }) readonly showAddStep!: boolean

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get flowPathsLimitReached () {
    return !this.currentOrganizationLimits.flowPaths
  }

  get pathAllowed () {
    return !this.step || !this.step.pathId
  }

  get stepAdd (): Partial<Pick<FlowStep, 'index' | 'pathId' | 'pathIndex'>> | null {
    if (this.step && this.step?.pathIndex !== null) {
      return {
        index: this.step.index,
        pathId: this.step.pathId,
        pathIndex: this.step.pathIndex + 0.5
      }
    } else if (this.step) {
      return {
        index: this.step.index + 0.5
      }
    } else {
      return null
    }
  }
}
